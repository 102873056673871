const { lazy } = require("react");

const Dashboard = lazy(() => import("../screens/Dashboard/index"));
const Faqs = lazy(() => import("../screens/Dashboard/Faqs"));
const Ticket = lazy(() => import("../screens/Dashboard/Ticket"));
const Privacy = lazy(() => import("../screens/Dashboard/Privacy"));
const Routes = lazy(() => import("../screens/Dashboard/Routes"));

const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
    name: "Dashboard",
  },
  {
    path: "/tickets/history",
    exact: true,
    component: Ticket,
    name: "Ticket",
  },
  {
    path: "/rcpt",
    exact: true,
    component: Ticket,
    name: "Ticket",
  },
  {
    path: "/faqs",
    exact: true,
    component: Faqs,
    name: "Faqs",
  },
  {
    path: "/terms",
    exact: true,
    component: Privacy,
    name: "Privacy",
  },
  {
    path: "/routes",
    exact: true,
    component: Routes,
    name: "Routes",
  },
];

export default routes;
